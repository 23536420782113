// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-angebot-chatbot-workshop-js": () => import("./../../../src/pages/angebot/chatbot-workshop.js" /* webpackChunkName: "component---src-pages-angebot-chatbot-workshop-js" */),
  "component---src-pages-angebot-js": () => import("./../../../src/pages/angebot.js" /* webpackChunkName: "component---src-pages-angebot-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-angebot-details-js": () => import("./../../../src/templates/angebotDetails.js" /* webpackChunkName: "component---src-templates-angebot-details-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blogCategory.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/blogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-referenzen-details-js": () => import("./../../../src/templates/referenzenDetails.js" /* webpackChunkName: "component---src-templates-referenzen-details-js" */),
  "component---src-templates-referenzen-js": () => import("./../../../src/templates/referenzen.js" /* webpackChunkName: "component---src-templates-referenzen-js" */),
  "component---src-templates-team-details-js": () => import("./../../../src/templates/teamDetails.js" /* webpackChunkName: "component---src-templates-team-details-js" */)
}

